import { useEffect, useState, useRef } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import { selectCurrentStore } from "../redux/storeSlice";
import { getLDContext, hasContextChanged } from "../utils/launchDarkly";
import GlobalLoading from "../common/components/GlobalLoading";

const LDContextManager = ({ children }) => {
  const ldClient = useLDClient();
  const prevContextRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);

  useEffect(() => {
    if (!ldClient) {
      console.log("⏳ ldClient is not available yet. Skipping update.");
      return;
    }

    const updateContext = async () => {
      try {
        const newContext = getLDContext(currentUser, currentStore);

        if (hasContextChanged(prevContextRef.current, newContext)) {
          setIsLoading(true);
          await ldClient.identify(newContext);
          prevContextRef.current = newContext;
        }
      } catch (err) {
        console.error("Error updating LaunchDarkly context:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentUser && currentStore) {
      updateContext();
    }
  }, [
    ldClient,
    currentUser?.id,
    currentUser?.email,
    currentStore?.role,
    currentStore?.store?.id,
    currentStore?.store?.organization_id,
  ]);

  return isLoading ? <GlobalLoading /> : <>{children}</>;
};

export default LDContextManager;
